// flatly
@import "~bootswatch/dist/pulse/variables";
@import "~bootstrap-scss/bootstrap";
@import "~bootswatch/dist/pulse/bootswatch";

@import "~react-csv-importer/dist/index.css";

.work-view-tabs {
  > .nav-item {
    > .nav-link {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }
}

footer a {
  text-decoration: none;
  color: $gray-600;
}